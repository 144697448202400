<template>
  <div class="pa-2">
    <v-subheader>Tutti i pagamenti</v-subheader>
    <v-divider class="pa-2"></v-divider>
    <CardList
      :cards="paymentUtilities"
      identifier-key="key"
      :finishLoading="true"
      show-load-more-in="none"
      :cardsClickable="true"
    >
      <template v-slot:card="{ card }">
        <v-card
          class="border-default mx-2 my-2 d-flex flex-column"
          @click="handleListClick(card)"
          dark
          :disabled="isOnlyWheelOfFortune"
        >
        <div
            v-if="Array.isArray(card.text)"
            class="text-center text-h6"
            style="margin-bottom: -28px"
          >
            <div v-for="t in card.text" :key="t">
              {{ t }}
            </div>
          </div>
          <div v-else class="text-center text-h6 pt-2" @click="handleListClick(card)">
            {{ card.text }}
          </div>
          <div
            style="height: 100px"
            class="flex-grow-1 d-flex justify-center align-center"
          >
            <v-icon x-large>{{ card.icon }}</v-icon>
          </div>
          <v-divider
            v-if="!!card.listRoute || !!card.newRoute || !!card.refreshRoute"
          ></v-divider>
          <div class="d-flex justify-end">
            <v-btn
              v-if="!!card.listRoute"
              icon
              class="mr-2"
              @click="handleListClick(card)"
            >
              <v-icon>mdi-format-list-bulleted</v-icon>
            </v-btn>
            <v-btn
              v-if="!!card.newRoute"
              icon
              class="mr-2"
              @click.stop="handlePlusClick(card)"
              :disabled="isLicenseBlocked"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn
              v-if="!!card.refreshRoute"
              icon
              class="mr-2"
              @click="handleRefreshClick(card)"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </div>
        </v-card>
      </template>
    </CardList>
    <v-subheader>Settings</v-subheader>
    <v-divider class="pa-2"></v-divider>
    <CardList
      :cards="paymentSettings"
      identifier-key="key"
      :finishLoading="true"
      show-load-more-in="none"
      :cardsClickable="true"
    >
      <template v-slot:card="{ card }">
        <v-card
          class="border-default mx-2 my-1 d-flex flex-column"
          @click.stop.prevent="handleListClick(card)"
          dark
          :disabled="isOnlyWheelOfFortune"
        >
          <div
            v-if="Array.isArray(card.text)"
            class="text-center text-h6"
            style="margin-bottom: -28px"
          >
            <div v-for="t in card.text" :key="t">
              {{ t }}
            </div>
          </div>
          <div v-else class="text-center text-h6 pt-2">
            {{ card.text }}
          </div>
          <div
            style="height: 100px"
            class="flex-grow-1 d-flex justify-center align-center"
          >
            <v-icon x-large>{{ card.icon }}</v-icon>
          </div>
          <v-divider></v-divider>
          <div class="d-flex justify-end">
            <v-btn
              icon
              class="mr-2"
              @click.stop.prevent="handleListClick(card)"
            >
              <v-icon>mdi-format-list-bulleted</v-icon>
            </v-btn>
            <v-btn
              icon
              class="mr-2"
              @click.stop.prevent="handlePlusClick(card)"
              v-if="card.name !== 'movementItems'"
              :disabled="isLicenseBlocked"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </v-card>
      </template>
    </CardList>

    <!--<v-subheader class="mt-4">Operazioni recenti</v-subheader>
    <div class="px-4">
      <RecentOperationsResults
        :operations="recentOperations"
      ></RecentOperationsResults>
    </div>-->
  </div>
</template>

<script>
import { CardList, SearchWithButton } from "likablehair-ui-components";
import paymentsManagement from "@/services/payments/payments.service.js";
import RecentOperationsResults from "@/components/areas/payments/search/RecentOperationsResults.vue";

export default {
  name: "PaymentsList",
  components: {
    CardList,
    SearchWithButton,
    RecentOperationsResults,
  },
  data: function () {
    return {
      paymentsUtility: [],
      historicalList: [],
      promotionsList: [],
      recentOperations: [],
      isLicenseBlocked: undefined,
      isOnlyWheelOfFortune: undefined
    };
  },
  mounted() {
    this.loadPayments();
    this.loadRecentOperation();
  },
  computed: {
    paymentUtilities() {
      return this.paymentsUtility.filter((el) => {
        return [
          "paymentTransactionsCollected",
          "operatorDetailPaymentTransaction",
          "paymentTransactionsGiven",
          "closures",
          "paymentCredits",
          "invoicesToCash",
          "invoicesToPay",
          "otherCosts",
          "otherCashes"
        ].includes(el.name);
      });
    },
    paymentSettings() {
      return this.paymentsUtility.filter((el) => {
        return [
          "cashDesks",
          "taxes",
          "schedulePaymentsSettings",
          "paymentTerms",
          /* keep comment, do we need these tabs?     
          "paymentConditions",
          "paymentTypeNatureExemption", */
        ].includes(el.name);
      });
    },
  },
  methods: {
    handlePlusClick(section) {
      this.goTo(section.newRoute);
    },
    handleListClick(section) {
      if (section.name == "closures") {
        this.goTo(section.refreshRoute);
      } else {
        this.goTo(section.listRoute);
      }
    },
    handleRefreshClick(section) {
      this.goTo(section.refreshRoute);
    },
    goTo(routeName) {
      this.$router.push({ name: routeName }).catch(() => {});
    },
    loadPayments() {
      paymentsManagement.list().then((data) => {
        this.paymentsUtility = data;
      });
    },
    loadRecentOperation() {
      paymentsManagement.lastRecentOperationsList().then((data) => {
        this.recentOperations = data;
      });
    },
  },
  isLicenseBlocked: {
    bind: "isLicenseBlocked",
  },
  isOnlyWheelOfFortune: {
    bind: "isOnlyWheelOfFortune",
  }
};
</script>

<style scoped>
.border-default {
  padding: 5px;
  border-radius: 10px;
  border: 0px;
  transition: all 0.2s;
  transform: scale(1);
  background-image: linear-gradient(rgb(138, 119, 226, 0.8), rgb(80, 191, 225, 0.8));
  width: 200px;
  height: 190px;
  background-color: white;
}
.border-default:hover {
  padding: 5px;
  border-radius: 20px;
  border: 0px;
  transition: all 0.2s;
  transform: scale(1.05);
  z-index: 10;
}
</style>