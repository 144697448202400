import recentOperationTracker from '@/services/common/recentOperationTracker'

class paymentsRecentOperationsDatabase extends recentOperationTracker {
  constructor() {
    super()
  }

  name() {
    return 'payment'
  }
}

export default new paymentsRecentOperationsDatabase()