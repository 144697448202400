class PaymentNamesMappings {
  constructor() {
    this.nameMappings = {
      'promos': 'Promozioni',
      'cashDesks': 'Casse',
      'invoices': 'Fatture',
      'closures': 'Corrispettivi',
      'paymentCredits': 'Sospesi Di Cassa',
      'taxes': 'Aliquota IVA',
      'schedulePaymentsSettings': 'Tabella Rateazioni',
      'paymentTerms': ['Modalità di','Pagamento'],
      'paymentConditions': ['Condizioni di', 'Pagamento'],
      'paymentTypeNatureExemption': ['Tipo natura', 'esenzione'],
      'paymentDebits': 'Storni',
      'operatorPaymentDebits': 'Spese per contanti',
      'paymentTransactionsCollected': ['Transazione','Di Cassa Incassati'],
      'operatorDetailPaymentTransaction' : ['Storico','Prodotti/Servizi'],
      'paymentTransactionsGiven': ['Transazione','Di Cassa Pagamenti'],
      'invoicesToPay': ['Fatture Ricevute','Da Pagare'],
      'invoicesToCash': ['Fatture Emesse','Da Incassare'],
      'otherCosts': 'Altri Costi',
      'otherCashes': 'Altri Ricavi',
    }

    this.iconPaymentsMappings = {
      'promos': 'mdi-tag-text-outline',
      'cashDesks': 'mdi-cash-register',
      'invoices': 'mdi-receipt',
      'closures': 'mdi-cash-check',
      'paymentCredits': 'mdi-bank',
      'taxes': 'mdi-percent',
      'schedulePaymentsSettings': 'mdi-calendar-multiple-check',
      'paymentTerms': 'mdi-receipt',
      'paymentConditions': 'mdi-cash-check',
      'paymentTypeNatureExemption': 'mdi-bank',
      'paymentDebits': 'mdi-account-reactivate-outline',
      'operatorPaymentDebits': 'mdi-city-variant-outline',
      'paymentTransactionsCollected': 'mdi-cash-plus',
      'operatorDetailPaymentTransaction': 'mdi-cash-100',
      'paymentTransactionsGiven': 'mdi-cash-minus',
      'invoicesToPay': 'mdi-archive-arrow-up-outline',
      'invoicesToCash': 'mdi-archive-arrow-down-outline',
      'otherCosts': 'mdi-cash-refund',
      'otherCashes': 'mdi-cash',
    }
  }

  getHumanName(codedName) {
    return this.nameMappings[codedName]
  }

  getPaymentIcon(paymentName) {
    return this.iconPaymentsMappings[paymentName]
  }
}

export default new PaymentNamesMappings()