
import paymentsList from './payments.json'
import PaymentNamesMappings from './paymentNameMappings'
import recentOperationsDatabase from '@/services/offline/payments/paymentsRecentOperations.database.js'


class paymentsManagment {
  constructor() {
    this.payments = paymentsList
    this._calculatePaymentsText()
  }

  list() {
    return Promise.resolve(this.payments)
  }

  _calculatePaymentsText() {
    for (let i = 0; i < this.payments.length; i++) {
      this.payments[i].text = PaymentNamesMappings.getHumanName(this.payments[i].name)
      this.payments[i].icon = PaymentNamesMappings.getPaymentIcon(this.payments[i].name)
    }
  }


  lastRecentOperationsList(number = 10) {
    return new Promise((resolve, reject) => {
      recentOperationsDatabase.getLastOperations(number).then((operations) => {
        resolve(operations)
      }).catch((err) => {
        reject(err)
      })
    })
  }
  
}

export default new paymentsManagment()